import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';


var stepper1Node = document.querySelector('#stepper1');
var stepper1 = new Stepper(document.querySelector('#stepper1'));

const password = document.getElementById("inputPassword");
const requirements = document.querySelectorAll(".requirements");
const passwordFeedback = document.getElementById("password-feedback");
const goodPasswordFeedback = document.getElementById("good-password-feedback");
const passwordProgressbar = document.getElementById("passwordProgressbar");

const phoneInput = document.querySelector("#phone");
const phoneErrorMsg = document.querySelector("#phone-error-msg");
const phoneValidMsg = document.querySelector("#phone-valid-msg");

// here, the index maps to the error code returned from getValidationError - see readme
const phoneErrorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];


// Function to change the card image randomly
function changeCardImage() {
    var images = ['wizard-0.jpg', 'wizard-1.jpg', 'wizard-2.jpg', 'wizard-3.jpg'];
    var randomImage = images[Math.floor(Math.random() * images.length)];
    var containers = document.getElementsByClassName('card-img-bottom');
    for(var i in containers) if(containers[i] instanceof HTMLImageElement) containers[i].src = 'images/' + randomImage;
}

// Event listeners for the stepper
stepper1Node.addEventListener('show.bs-stepper', function (event) {
    console.warn('show.bs-stepper', event);
    changeCardImage(); // Change image on show event
});
stepper1Node.addEventListener('shown.bs-stepper', function (event) {
    console.warn('shown.bs-stepper', event);
    changeCardImage(); // Change image on shown event
});

// Función para actualizar la propiedad 'required' del campo de texto
function updateTextFieldRequirement(checkbox, texto) {
    if (checkbox.checked) {
        texto.required = true; // El campo de texto es requerido si el checkbox está marcado
    } else {
        texto.required = false; // No es requerido si el checkbox no está marcado
        texto.setCustomValidity(""); // Limpiar cualquier estado de validación personalizada
    }
}

function validateAndNext() {
    var form = document.querySelector('.bs-stepper-content .active form');

    if(form.id === "form-step-2") {
        validatePhone();
    }

    if (form.checkValidity()) {
        clearError("emailHelp", "");
        clearError("subdomainHelp", "Want a custom domain? <a href=\"mailto:admin@boosterbooking.com\">Contact us!</a>");
        
        stepper1.next();

    } else {
        form.reportValidity();
    }
}

function createAccount() {
    clearError("emailHelp", "");
    clearError("subdomainHelp", "Want a custom domain? <a href=\"mailto:admin@boosterbooking.com\">Contact us!</a>");

    var form = document.getElementById('form-step-3'); // Assuming this is the form ID for the business details

    var domainType = document.querySelector('input[name="domain_type"]:checked').value
    var domainName = "";
    var subdomainName = "";
    var bookingsOnly = false;

    switch(domainType){
        case "subdomain":
            form = document.getElementById('form-subdomain');
            domainType = "Subdomain";
            var subdomain = document.querySelector('#inputSubdomain');
            if(subdomain !== null) subdomainName = cleanAlphaNumericValues(subdomain.value);
            break;
    
        case "domain":
            form = document.getElementById('form-domain');
            domainType = "Domain";
            
            var domain = document.querySelector('#inputDomain');
            if(domain !== null) domainName = cleanDomain(domain.value);

            break;

        case "domain_advanced":
            form = document.getElementById('form-domain-advanced');
            domainType = "Advanced";

            var advancedDomain = document.querySelector('#inputAdvancedDomain');
            if(advancedDomain !== null) domainName = cleanAlphaNumericValues(advancedDomain.value);
            var advancedSubdomain = document.querySelector('#inputAdvancedSubdomain');
            if(advancedSubdomain !== null) subdomainName = cleanAlphaNumericValues(advancedSubdomain.value);

            var inputBookingsOnly = form.querySelector('#inputBookingsOnly');
            var inputBookingsOnlyValue = inputBookingsOnly === null ? false : inputBookingsOnly.value;
            var bookingsOnly = false;
            if (inputBookingsOnlyValue === "yes") bookingsOnly = true;

            break;
    }

    if (form.checkValidity()) {

        // Retrieve CSRF token value from the hidden input in the second form
        var csrfToken = document.querySelector('#form-step-3 [name="_csrf"]').value;
        console.log('_csrf Token', csrfToken);

        var formData = {
            name: cleanAlphaNumericValues(document.getElementById('inputName').value),
            surname: cleanAlphaNumericValues(document.getElementById('inputSurname').value),
            email: document.getElementById('inputEmail').value, // Assuming email validation is handled elsewhere
            password: removeSpaces(document.getElementById('inputPassword').value),
            companyName: cleanAlphaNumericValues(document.getElementById('inputCompanyName').value),
            businessType: cleanAlphaNumericValues(document.getElementById('inputBusinessType').value),
            phone: cleanAlphaNumericValues(document.getElementById('phone').value),
            country: document.getElementById('inputCountry').value, // Assuming country validation is handled elsewhere
            domainType: domainType,
            domain: domainName,
            bookingsOnly: bookingsOnly,
            subdomain: subdomainName, // Assuming subdomain validation is handled elsewhere
            deployment: cleanAlphaNumericValues(document.getElementById('inputDeployment').value),
            tosAccepted: document.getElementById('termsAndConditionsAccepted').value == "on",
            mailAccepted: document.getElementById('marketingEmailsAccepted').value == "on"
        };


        console.log(formData);
        //console.log(JSON.stringify(formData));
        var createButton = document.getElementById("create-button");
        var createButtonContent = createButton.innerHTML;
        createButton.disabled = true;
        createButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Signing Up...';

        // AJAX POST request with fetch
        fetch('/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                
                createButton.disabled = false;
                createButton.innerHTML = createButtonContent;
                console.log('Response status:', response.status);
                if (!response.ok) {
                    if (response.status === 409) {
                        // Handle 409 Conflict specifically
                        return response.json().then(data => {
                            console.log('Error (409):', data.message); // Assuming the error message is in data.message
                            //throw new Error('User already exists.');
                            if (data.message.includes("email")) {
                                showError("emailHelp", data.message);
                                stepper1.previous();
                                stepper1.previous();
                            } else if (data.message.includes("subdomain")) {
                                showError("subdomainHelp", data.message);
                                stepper1.previous();
                            }
                        });
                    } else {
                        // Handle other errors
                        return response.json().then(data => {
                            console.error('Error (' + response.status + "):", data.message); // Log the error message for other errors
                            //throw new Error('Network response was not ok: ' + response.statusText);
                        });
                    }
                }
                return response.json();
                //return response;
            })
            .then(data => {
                console.log('Success:', data);
                // Wait for 15 seconds before showing the access button
                console.log(`Deploy ID: ${data.jobUrl}`);
                console.log(`Ticket: ${data.jiraTicket}`);
                console.log(`Ticket URL: ${data.ticketUrl}`);

                //call the checker
                
                changeTitleAndUrl(data.statusId);
                deployUser(data.statusId, csrfToken);

                //setTimeout(showAccessButton, 15000);
                //stepper1.next();
            })
            .catch((error) => {
                console.log('Error String:' + error.toString());
                console.log('Error Message:' + error.message);
                console.log(error);

                //console.error('Error:', error);
                //setTimeout(showAccessButton, 15000);
            });
        //Transition to next step
        //todo: temporary disabled
        //stepper1.next();
    } else {
        form.reportValidity();
    }
}

function showAccessButton(domain) {

    
    document.getElementById('cardTitle').innerHTML = "Your Website Now Online!";
    document.getElementById('cardText').innerHTML = "<strong>Congratulations!</strong> Your website is now live! You can access it at the link below or use the admin panel to configure and customize it. Happy Journey!";

    // Hide the row with Next and Previous buttons
    document.getElementById('loadingRow').classList.add('d-none');

    // Show the row with the Access button and the completed message
    document.getElementById('accessRow').classList.remove('d-none');
    document.getElementById('completedRow').classList.remove('d-none');

    // Update the URL in the access button and the link in the completed message
    const accessButton = document.getElementById('accessButton');
    const accessUrl = document.getElementById('accessUrl');

    
    // Update the URL in the access button and the link in the completed message
    const adminAccessButton = document.getElementById('adminAccessButton');
    const adminAccessUrl = document.getElementById('adminAccessUrl');

    // Set the href and text content for both
    accessButton.href = domain;
    accessUrl.href = domain;
    accessUrl.innerHTML = domain;
    
    adminAccessButton.href = domain + "/admin";
    adminAccessUrl.href = domain + "/admin";
    adminAccessUrl.innerHTML = domain + "/admin";

    changeCardImage(); // Change image on shown event (make sure this function exists)
}


function showError(idElement, message) {
    var smallDiv = document.getElementById(idElement);
    smallDiv.classList.add('error-field');
    smallDiv.classList.remove('text-muted');
    smallDiv.textContent = message;
}

function clearError(idElement, message) {
    var smallDiv = document.getElementById(idElement);
    smallDiv.classList.remove('error-field');
    smallDiv.classList.add('text-muted');
    smallDiv.innerHTML = message;
}

function changeTitleAndUrl(statusId) {
    // Obtener el título actual y la URL
    const currentTitle = document.title;
    const currentPath = window.location.pathname;
    
    // Modificar el título y la URL
    const newTitle = "Setting up... " + currentTitle;
    const newPath = currentPath + "?statusId=" + statusId; // Puedes modificar esta parte según lo que necesites
    
    // Cambiar el título de la página
    document.title = newTitle;
    
    // Cambiar la URL usando history.pushState o history.replaceState
    history.replaceState({page: "setup"}, newTitle, newPath);
}


function deployUser(statusId, csrfToken) {

    document.getElementById('cardTitle').innerHTML = "Just a Few Moments Away";
    document.getElementById('cardText').innerHTML = "You are now registered and <strong>your site is being created</strong>. Please be patient, it will take just a few minutes.";

    stepper1.next();

    const checkDeploymentStatus = () => {
        fetchDeploymentStatus(statusId, csrfToken)
            .then(handleDeploymentStatus)
            .catch(handleError);
    };

    const fetchDeploymentStatus = (statusId, csrfToken) => {
        return fetch(`/register/status?statusId=${statusId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": csrfToken
            },
        });
    };

    const handleDeploymentStatus = (response) => {
        if (response.status === 200) {
            return response.json()
                .then((statusObj) => {
                    if (statusObj.status === "completed") {
                        console.log("Deployment completed");
                        showAccessButton(statusObj.domain);
                    } else {
                        console.log("In progress...");
                        const randomMessage = getRandomInspirationalMessage();
                        updateLoadingMessage(randomMessage);
                        updateRemainingTime(statusObj.duration, statusObj.estimatedDuration);
                        setTimeout(checkDeploymentStatus, 10000);
                    }
                });
        } else {
            console.error("Error checking deployment status. Status code:", response.status);
            setTimeout(checkDeploymentStatus, 10000);
        }
    };

    const handleError = (error) => {
        console.error("Error checking deployment status:", error);
        setTimeout(checkDeploymentStatus, 10000);
    };

    // Función simplificada para actualizar el tiempo restante, con conversión de milisegundos a segundos
    const updateRemainingTime = (duration, estimatedDuration) => {
        const remainingTimeElement = document.getElementById('remainingTime');
        let remainingTimeInSeconds = (estimatedDuration - duration) / 1000; // Convertir a segundos

        if (remainingTimeInSeconds >= 60) {
            remainingTimeElement.innerHTML = `${Math.floor(remainingTimeInSeconds / 60)} min`;
        } else if (remainingTimeInSeconds >= 45) {
            remainingTimeElement.innerHTML = "less than 1 min";
        } else if (remainingTimeInSeconds >= 30) {
            remainingTimeElement.innerHTML = "less than 45 seconds";
        } else if (remainingTimeInSeconds >= 15) {
            remainingTimeElement.innerHTML = "less than 30 seconds";
        } else if (remainingTimeInSeconds >= 1) {
            remainingTimeElement.innerHTML = "less than 15 seconds";
        } else {
            remainingTimeElement.innerHTML = "almost ready...";
        }
    };

    // Iniciar la verificación del estado del despliegue
    checkDeploymentStatus();
}


// Inspirational messages array
const inspirationalMessages = [
    "Exciting adventures await! Your platform is in the works, and soon you'll be guiding travelers to incredible experiences. 🌍✈️",
    "Imagine the places you'll go! While we craft your platform, get ready to explore the world with BoosterBooking. 🗺️🌟",
    "Your journey begins here! We're preparing your platform so you can start your own travel business with ease. 🚀🌄",
    "Get ready to turn your passion into profit! Your BoosterBooking platform is just around the corner. 📈💼",
    "Adventure is calling, and your platform is the key! Unlock new horizons with BoosterBooking. 🗝️🌅",
    "We're creating your portal to endless opportunities! Soon, you'll be guiding travelers and making dreams come true. 🌠🌟",
    "With your BoosterBooking platform, you'll be the captain of your own ship, charting a course to success! ⚓🌊",
    "The world is your playground, and your BoosterBooking platform is the ticket to your next big adventure. 🌐🎫",
    "Prepare to shine! Your BoosterBooking platform is under construction, and soon you'll be a star in the travel world. ✨🌟",
    "Every journey begins with a single step, and yours starts with your BoosterBooking platform. Get ready to thrive! 👣🌆"
];

// Function to get a random inspirational message as a string
function getRandomInspirationalMessage() {
    const randomIndex = Math.floor(Math.random() * inspirationalMessages.length);
    return inspirationalMessages[randomIndex];
}

function updateLoadingMessage(message) {
    const loadingMessageElement = document.getElementById('loadingMessage');
    if (loadingMessageElement) {
        loadingMessageElement.textContent = message;
    }
}

function cleanAlphaNumericValues(textValue) {   
    if(textValue === null)
        return null;
    return textValue.replace(/[^a-zA-Z0-9 ]/g, ""); // Keep alphanumeric characters and spaces
}

function removeSpaces(passValue) {
    return passValue.replace(/\s+/g, ""); // Remove all spaces
}

function cleanDomain(domain) {
    // Allow letters, numbers, hyphens, and dots
    return domain.replace(/[^a-zA-Z0-9.-]/g, "");
}

function togglePasswordVisibility() {
    var passwordInput = document.getElementById('inputPassword');
    var toggleCheckbox = document.getElementById('togglePassword');

    if (toggleCheckbox.checked) {
        passwordInput.type = 'text';
    } else {
        passwordInput.type = 'password';
    }
}

function getDomains(query){

    // Example:
    //  https://enrollment.boosterbooking.cloud/checkdomain?domainName=naruto
    // Output:
    // {
    //   "available": false,
    //   "unavailable": true
    // }

    // Simulate API call for demonstration
    
    var csrfToken = document.querySelector('#form-step-3 [name="_csrf"]').value;
    return fetch(`/checkdomain?domainName=${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRF-TOKEN": csrfToken
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

let domainQueries = {};
let domainQueriesCache = {};
let lastQuery;
let timeout;
function getDomainsTimeout(query) {

    // If there is a previous request that hasn't failed

    if (lastQuery != null){
        clearTimeout(timeout);
        lastQuery = null;
    }

    return new Promise((resolve, reject) => 
        { 
            if(domainQueriesCache[query]){
                resolve(domainQueriesCache[query]);
            } else if(!domainQueries[query]){
                timeout = window.setTimeout(() => {
                    
                    if(!domainQueries[query])
                        domainQueries[query] = getDomains(query);
        
                    domainQueries[query]
                        .then(response => {
                            domainQueriesCache[query] = response;
                            if(query == lastQuery){

                                let sources = ["unavailable", "invalid"];
                                sources.forEach(source => {
                                    if(!response[source]) return;
                                    let replaceSourceWith = [];
                                    for(var domain in response[source]['domain']){
                                        replaceSourceWith.push({
                                            duration: 0,
                                            premium: 0,
                                            price: 0.0,
                                            renew: 0.0,
                                            content: response[source]['domain'][domain]
                                        });
                                    }
                                    response[source] = {"domain": replaceSourceWith};
                                });

                                resolve(domainQueriesCache[query]);
                                lastQuery = null;
                            }
                        })
                        .catch(error => {
                            
                            if(query == lastQuery){
                                reject(error)
                                lastQuery = null;
                            }else{                    
                                console.log(error);
                            }
                        })
            
                }, 500)
            }

            lastQuery = query;
        } );
}





function onLoad(){
    // Comprobar si 'setup=true' ya está en la URL
    const urlParams = new URLSearchParams(window.location.search);
    const statusId = urlParams.get('statusId');
    if(statusId !== undefined && statusId !== null && statusId != ""){
        stepper1.next(); // Moves to Business
        stepper1.next(); // Moves to Domain
        
        var csrfToken = document.querySelector('#form-step-3 [name="_csrf"]').value;
        deployUser(statusId, csrfToken); // Moves to deploy
    }
    changeCardImage();
}

addEventListener("DOMContentLoaded", (event) => {

    document.getElementById("togglePassword").onclick = togglePasswordVisibility;    
    Array.from(document.getElementsByClassName("next-button")).forEach(button => button.onclick = validateAndNext);
    Array.from(document.getElementsByClassName("previous-button")).forEach(button => button.onclick = () => stepper1.previous());
    Array.from(document.getElementsByClassName("create-button")).forEach(button => button.onclick = createAccount);

    // Initialize Algolia autocomplete for domain input
    autocomplete({
        container: '#inputDomain',
        getSources({ query }) {
            return getDomainsTimeout(query)
              .then((response) => {
                let sourceList = [];
                let sources = ["available", "unavailable", "invalid"];
                
                sources.forEach(source => {
                    if(!response[source]) return;
                    var sourceData = {
                        sourceId: source,
                        getItems({ query }) {
                            return response[source]['domain'];
                        },
                    };
                    if(source == "available"){
                        sourceData.getItemUrl = ({ item }) => item.url;
                    }

                    switch(source){
                        case "available": 
                            sourceData.templates = { item({ item, html }){
                                    return html`<div class="list-group-item-action">${item.content} for <span class="domain-price">${item.price} USD</span></div>`;
                            }}; break;
                        case "unavailable": 
                            sourceData.templates = { item({ item, html }){
                                return html`<div class="list-group-item-action text-warning disabled">${item.content} is unavailable</span></div>`;
                            }}; break;
                        case "invalid": 
                            sourceData.templates = { item({ item, html }){
                                return html`<div class="list-group-item-action text-muted disabled">${item.content} is invalid</span></div>`;
                            }}; break;
                    }

                    sourceList.push(sourceData);
                })

                return sourceList;
            });
        }
    });

    requirements.forEach((element) => element.classList.add("wrong"));

    password.addEventListener("focus", validatePassword);
    password.addEventListener("input", validatePassword);

    // on keyup / change flag: reset
    phoneInput.addEventListener('change', phoneReset);
    phoneInput.addEventListener('keyup', phoneReset);

    const checkbox = document.getElementById('inputBookingsOnly');
    const texto = document.getElementById('inputDomain');
    
    // Evento para cambiar la validación cuando el estado del checkbox cambia
    checkbox.addEventListener('change', function () {
        updateTextFieldRequirement(checkbox, texto);
        form.reportValidity(); // Opcional: para reportar inmediatamente la validación
    });

    function validatePassword(){
        
        if (!password.classList.contains("is-valid")) {
            password.classList.add("is-invalid");
        }

        const value = password.value;
        const minLenght = 8;
        const upperCases = countRegex(/[A-Z]/g, value);
        const lowerCases = countRegex(/[a-z]/g, value);
        const numbers = countRegex(/\d/g, value);
        const spaces = countRegex(/\s/g, value);
        const specialChars = value.length - upperCases - lowerCases - numbers - spaces;
        const isPasswordValid = value.length >= minLenght && upperCases > 0 && lowerCases > 0 && numbers > 0 && specialChars > 0;

        let strength = updatePasswordFeedback(value, minLenght, upperCases, lowerCases, numbers, specialChars);

        if (strength == "Strong" || strength == "Very Strong") {
            password.setCustomValidity("");
        } else {
            password.setCustomValidity(strength);
        }

        if (isPasswordValid) {
            password.classList.remove("is-invalid");
            password.classList.add("is-valid");

            requirements.forEach((element) => {
                element.classList.remove("wrong");
                element.classList.add("good");
            });

        } else {
            password.classList.remove("is-valid");
            password.classList.add("is-invalid");
        }
    }

    function updatePasswordFeedback(value, minLenght, upperCases, lowerCases, numbers, specialChars){
        
        var messageCase = new Array();

        // Check password values
        if (value.length < minLenght) messageCase.push(`${minLenght - value.length} more characters`);
        if (specialChars == 0) messageCase.push("1 special character"); // Special Charector
        if (upperCases == 0) messageCase.push("1 big letter");      // Uppercase Alpabates
        if (numbers == 0) messageCase.push("1 number");      // Numbers
        if (lowerCases == 0) messageCase.push("1 small letter");     // Lowercase Alphabates

        // Display it
        var progressbar = 0;
        var strength = "";
        var bClass = "";
        switch (5 - messageCase.length) {
            case 0:
            case 1:
                strength = "Way too weak";
                progressbar = 15;
                bClass = "bg-danger";
                break;
            case 2:
                strength = "Very weak";
                progressbar = 25;
                bClass = "bg-danger";
                break;
            case 3:
                strength = "Weak";
                progressbar = 34;
                bClass = "bg-warning";
                break;
            case 4:
                strength = "Medium";
                progressbar = 50;
                bClass = "bg-warning";
                break;
            case 5:
                strength = "Strong";
                progressbar = 65;
                bClass = "bg-success";
                break;

        }

        // Add the number of occurences of highly valued characters
        progressbar += upperCases + numbers + specialChars * 2;
        // Add the number of characters multiplied by 1.5
        progressbar += value.length * 1.5;

        if(progressbar > 100){
            progressbar = 100;
            strength = "Very Strong";
            bClass = "bg-success";
        }

        let passwordProgressbarJquery = $(passwordProgressbar);
        passwordProgressbarJquery.removeClass("bg-danger bg-warning bg-success").addClass(bClass);
        passwordProgressbarJquery.attr("aria-valuenow", progressbar)
        passwordProgressbarJquery.width(progressbar + "%");

        // Update password feedback
        if(messageCase.length > 0) {
            let message = `<strong>${strength}</strong>. It needs at least ${messageCase.slice(0, -1).join(", ")}`;
            if (messageCase.length > 1) {
                message += " and ";
            }
            message += messageCase[messageCase.length - 1] + ".";
            passwordFeedback.innerHTML = message;
        }else{
            goodPasswordFeedback.innerHTML = `<strong>${strength}</strong>`;
        }

        return strength;
    }
});    

function validatePhone(){
    phoneReset();
    if (!phoneInput.value.trim()) {
        phoneShowError("Required");
    } else if (iti.isValidNumber()) {
        phoneValidMsg.classList.remove("hide");
        phoneInput.classList.remove("is-invalid");
        phoneInput.classList.add("is-valid");
        phoneInput.setCustomValidity("");
    } else {
        const errorCode = iti.getValidationError();
        const msg = phoneErrorMap[errorCode] || "Invalid number";
        phoneInput.classList.remove("is-valid");
        phoneInput.classList.add("is-invalid");
        phoneInput.setCustomValidity(msg);
        phoneShowError(msg);
    }       
}

const phoneReset = () => {
    phoneInput.classList.remove("error");
    phoneErrorMsg.innerHTML = "";
    phoneErrorMsg.classList.add("hide");
    phoneValidMsg.classList.add("hide");
};

const phoneShowError = (msg) => {
    phoneInput.classList.add("error");
    phoneErrorMsg.innerHTML = msg;
    phoneErrorMsg.classList.remove("hide");
};



function countRegex(regex, str) {
    return (str.match(regex) || []).length;
}

// Change the card image when the page loads
window.onload = onLoad;
